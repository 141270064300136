import React from "react"
import { graphql, Link } from 'gatsby'
import { motion } from "framer-motion"
import { fadeSlow, revealInOut } from "../helpers/transitionHelper"
import {Helmet} from "react-helmet";
import urlFor from '../helpers/imageBuilder'

function padLeft(nr, n, str) {
    return Array(n-String(nr).length+1).join(str||'0')+nr;
}
const IndexPage = ({data, childAnimationDelay}) => {

  const projects = [
    ...data.allSanityResearch.nodes.filter(p => !!p.date),
    ...data.allSanityResearch.nodes.filter(p => !p.date)
  ]

  return (
    <motion.section
      initial="initial"
      animate="enter"
      exit="exit"
    >    
      <Helmet>
        <title>Projects - {data.sanitySiteSettings.title}</title>
      </Helmet>
      <motion.div variants={fadeSlow}>
        <motion.div variants={{
          enter: { transition: { 
            staggerChildren: .1,
            delayChildren: childAnimationDelay
          }}
        }}>
          <motion.h1 variants={revealInOut} className="page-title">Projects</motion.h1>
          <motion.div 
            className="blog-list" 
            variants={{
              enter: { transition: { 
                staggerChildren: .05,
                delayChildren: 0
              }}
            }}>
            {projects.map((research, i) => (
            <Link to={`/projects/${research.slug.current}`}>
              <motion.div className="blog-list-row" variants={revealInOut}>
                <div className="blog-index">{padLeft(i + 1, 2)}</div>
                <div className="blog-date">{research.date}</div>
                <img src={urlFor(research._rawImage).width(600).url()} alt={research.title} />
                <div className="blog-title">{research.title}</div>
                <div className="blog-arrow">
                  <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.9142 0.585785L27.8284 14.5L13.9142 28.4142L11.0858 25.5858L20.1716 16.5H0V12.5H20.1716L11.0858 3.41421L13.9142 0.585785Z" fill="black"/>
                  </svg>
                </div>
              </motion.div>
              </Link>
            ))}
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.section>
  )
}

export default IndexPage

export const query = graphql`
  query AllResearchQuery {
    sanitySiteSettings {
      title
    }
    allSanityResearch(sort: {fields: date, order: DESC}) {
      nodes {
        title
        slug {
          current
        }
        date
        _rawImage
      }
    }
  }
`